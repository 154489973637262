// src/components/BusinessInfo.js
import React from 'react';

const BusinessInfo = () => {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>WritingFreelancer</h1>
      <p><strong>Address:</strong> Kurunegala, Sri Lanka, 60000</p>
      <p><strong>Contact Number:</strong> +94 72 464 0438</p>
      <p><strong>Email:</strong> writingfreelancer.online@gmail.com</p>
    </div>
  );
};

export default BusinessInfo;
