// src/pages/Home.js
import React from 'react';
import BusinessInfo from '../components/BusinessInfo';

const Home = () => {
  return (
    <div className="home">
      <h2>Welcome !</h2>
      <p>Essays, term papers, research papers, and other complex works that are challenging to handle independently are what we offer efficient solutions for.</p>
      <BusinessInfo />
    </div>
  );
};

export default Home;
